article, 
aside, 
details, 
figcaption, 
figure, 
footer, 
header, 
hgroup, 
main, 
menu, 
nav, 
section, 
summary                                   { display: block; }
body                                      { background-color: #ecf0f5; /* height: 1000px; */ }

.react-select__control                    { font-family: "Roboto","Helvetica","Arial",sans-serif !important; padding: 4.5px 4px 8px !important; height: 3.4375em !important; margin: 0 !important; -webkit-tap-highlight-color: transparent !important; min-width: 0 !important; -webkit-animation-name: mui-auto-fill-cancel !important; animation-name: mui-auto-fill-cancel !important; -webkit-animation-duration: 10ms !important; animation-duration: 10ms !important; padding-right: 0 !important; }
.react-select__control:hover              { border-color: #000 !important;  }
.react-select__control:focus              { border-color: blue !important;  }
.react-select__menu                       { z-index: 2000 !important; }

.react-select__indicators                 { height: 150% !important;  margin: -10px 0 !important; }



html,
body                                      { margin:0; padding:0; background-color: #222d32 !important; }
body:not(.layout-fixed) .main-sidebar     { position: absolute; top: 0;}

@media (min-width: 768px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    margin-left: 270px;
  }
}

a                                         { text-decoration:none; cursor:pointer; }
a:active,
a:hover                                   { color: #dd4b39; }
label                                     { font-weight:normal; padding-top:0.33em; }
h1                                        { font-size: 20px; }
h2                                        { font-size: 18px; }
h3                                        { font-size: 16px; }
h4                                        { font-size: 14px; margin:0; }
/* color: #8aa4af;  */
h5                                        { font-size: 12px; margin:0; }

.absolute                                 { position:absolute; }
.action-cell button                       { min-width: 36px; }
.action-cell .dropdown-menu               { min-width:60px !important; width: 75px !important; background-color: #333; }
.action-cell .dropdown-menu>li>a          { padding:3px 10px; color:#b8c7ce; font-size:12px; }
.action-cell .dropdown-menu>li>a>.glyphicon { padding:3px 0; color:#b8c7ce; font-size:10px; }
.action-cell .dropdown-menu>li>a:hover    { background-color:#222d32; color:#ffffff; }
.action-cell .dropdown-menu>li>a:hover>.glyphicon { background-color:#222d32; color:#ffffff; }

.action-icon					                    { text-align:center; flex:0 0 50px !important; }
.action-icon.rt-td		                    { cursor:pointer; }
.active-indicator                         { margin-right: .5em; }
.tis-alert                                { border-radius:0; border-bottom-left-radius:.33em; border-bottom-right-radius:.33em; border-top:none; padding: .5em 1em 10px; color: #FFF; 
  /* transform: translate(calc(50vw - 200px));  */
  /* margin-left: -200px; */
  /* width: 400px;
  left: 50vw;
  margin-left: -200px;
  position: absolute; */
  background-color: #0097C4; border-color:#3925D7; white-space: b;}
.tis-alert-container .tis-alert-close               { 
  position: absolute;
  right: -12px;
  top: -12px;
  border-radius: 20px; 
  font-size: 22px !important; 
  color: #DDD;
  cursor: pointer;
}
.tis-alert-container .tis-alert-close:hover         { color: #FFF; }
.success.tis-alert-close       { background-color: #4B8E00 !important;}
.info.tis-alert-close          { background-color: #0097C4 !important;}
.warn.tis-alert-close          { background-color: #DD9D39 !important;}
.warning.tis-alert-close       { background-color: #DD9D39 !important;}
.error.tis-alert-close         { background-color: #dd4b39 !important;}

.tis-alert-success                        { background-color: #4B8E00 !important; border-color:#25D739; }
.tis-alert-info                           { background-color: #0097C4 !important; border-color:#3925D7; }
.tis-alert-warn, .tis-alert-warning       { background-color: #DD9D39 !important; border-color:#D79225; }
.tis-alert-error                          { background-color: #dd4b39 !important; border-color:#d73925; }
.tis-alert-container                      { 
  position: fixed; 
  z-index: 99100;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  top: 48px;
}

.tis-bar-chart                            { padding: 1em; border: 1px dashed #d73925; margin: 1em 0; }
.tis-bar-chart .body                      { margin: 1em 0; }
.tis-bar-chart .title                     { text-align: center; font-weight: bold; font-size: 1em; }
.tis-bar-chart .tis-bar                   { border: 1px solid #CCC;  margin: .5em 0; position: relative; padding: .5em 1em; background-color: #CCC; height:2em; }
.tis-bar-chart .tis-bar-label             { font-weight: bold; margin-top: 1em; height: 1em; text-align: right; }
.tis-bar-chart .tis-inner-bar             { z-index: 100; padding: .5em; position: absolute; top: 0; left: 0; height: 100%; }

.tis-bar-chart-slim .body                 { margin: 0; }
.tis-bar-chart-slim .title                { text-align: center; font-weight: bold; }
.tis-bar-chart-slim .tis-bar-label        { font-size: 1rem; }
.tis-bar-chart-slim .tis-bar              { border: 1px solid #999; border-radius: .25em; margin: .25em 0; position: relative; padding: 0; background-color: #CCC; height:7px; }
.tis-bar-chart-slim .tis-inner-bar        { /*z-index: 100;*/ padding: 0; border-radius: .25em; position: absolute; top: 0; left: 0; height: 100%; }

.no-finding .tis-inner-bar { background-color: rgb(0, 196, 121); border: 1px solid rgb(28, 95, 42); }
.improvement-opportunity .tis-inner-bar { background-color: rgb(0, 196, 121); border: 1px solid rgb(28, 95, 42); }
.non-conformance .tis-inner-bar { background-color: rgb(233, 171, 12); border: 1px solid rgb(114, 79, 22); }
.conditional-non-conformance .tis-inner-bar { background-color: rgb(195, 82, 82); border: 1px solid rgb(104, 30, 30); }
.high-risk-non-conformance .tis-inner-bar { background-color: rgb(195, 82, 82); border: 1px solid rgb(104, 30, 30); }

.progress-high .tis-inner-bar { background-color: rgb(0, 196, 121); border: 1px solid rgb(28, 95, 42); }
.progress-med .tis-inner-bar { background-color: rgb(233, 171, 12); border: 1px solid rgb(114, 79, 22); }
.progress-low .tis-inner-bar { background-color: rgb(195, 82, 82); border: 1px solid rgb(104, 30, 30); }

.align-right                              { text-align: right; }
/* .content-wrapper > .content.body          { position:relative; padding: 0 2em 2.5em !important; min-height: calc(100vh - 2.5em) !important;} */
.content-wrapper > .content.body          { position:relative; min-height: calc(100vh - 2.5em) !important;}

.bg-aqua                                  { background-color:#0197bd !important; color: #FFF !important;}
.bg-green                                 { background-color:#00a65a !important; color: #FFF !important;}
.bg-forest                                { background-color:#004728 !important; color: #FFF !important;}
.bg-red                                   { background-color:#dd4b39 !important; color: #FFF !important;}
.bg-yellow                                { background-color:#f39c12 !important; color: #FFF !important;}
.bg-gray                                  { background-color:#d2d6de !important; color: #333 !important;}
.bg-purple                                { background-color:#663399 !important; color: #FFF !important;}
/* .bg-aqua                                  { background-color:#00c0ef !important; color: #FFF !important; text-shadow: -1px -1px 1px #999, 1px 1px 1px #333;}
.bg-green                                 { background-color:#00a65a !important; color: #FFF !important; text-shadow: -1px -1px 1px #999, 1px 1px 1px #333;}
.bg-forest                                { background-color:#003947 !important; color: #FFF !important; text-shadow: -1px -1px 1px #999, 1px 1px 1px #333;}
.bg-red                                   { background-color:#dd4b39 !important; color: #FFF !important; text-shadow: -1px -1px 1px #999, 1px 1px 1px #333;}
.bg-yellow                                { background-color:#f39c12 !important; color: #FFF !important; text-shadow: -1px -1px 1px #999, 1px 1px 1px #333;}
.bg-gray                                  { background-color:#d2d6de !important; color: #333 !important; text-shadow: -1px -1px 1px #FFF, 1px 1px 1px #999;}
.bg-purple                                { background-color:#663399 !important; color: #FFF !important; text-shadow: -1px -1px 1px #999, 1px 1px 1px #333;} */
.bold                                     { font-weight:bold; }
.box                                      { overflow:visible; }
.box-body                                 { padding: 1em; }
.box-default                              { position:relative; overflow: visible; }
.box-command-bar                          { float: right }
.box-title                                { float: left; color:#3c8dbc; font-weight:bold; }
.box-scrolling                            { height:40em; overflow-y:auto; }
.box-menu li .treeview-menu               { margin: 0 1px; background: #FFF;}
.btn-icon                                 { position: relative; top: 1px; }

.category                                 { margin-bottom: .5em !important;}
.category > .MuiCardHeader-root           { background-color: #efefef !important;}
.category.condensed > .MuiCardHeader-root { padding-top: 4px !important; padding-bottom: 4px !important; }
.category .tis-collapse                   { width: 100%; text-align: center; cursor: pointer; padding: 0 0 2em; }


.center                                   { text-align:center; }
.chart-legend                             { text-align:center; margin:1em auto; width:95%; }
.chart-legend-item                        { white-space: nowrap; display:inline-block; margin:0.25em 0.75em; }
.chart-tooltip                            { color:#999999; margin: 0px; padding: 10px; width: 500px; background-color: rgb(255, 255, 255); border: 1px solid rgb(204, 204, 204); white-space: nowrap;  }
.chart-tooltip .row                       { min-height: auto; }
.chart-tooltip .title-label               { color:#999999; font-size: 18px; width:100%; font-weight:normal; display:inline-block; text-align:left; padding:0 2em 0 0 !important; }
.chart-tooltip .title-value               { color:inherit; font-size: 18px; font-weight:normal; width:100%; display:inline-block; text-align:left; padding:0 !important; }
.chart-tooltip .label                     { color:inherit; font-size: 12px; font-weight:normal; width:100%; display:inline-block; text-align:left; padding:0 2em 0 0 !important; }
.chart-tooltip .value                     { color:inherit; font-size: 12px; font-weight:normal; width:100%; display:inline-block; text-align:left; padding:0 !important; }
.chart-tooltip-mini                       { width:auto; max-width: 300px !important; color:rgb(153, 153, 153); }
.chart-tooltip-mini .title-label          { font-size: 100%; margin-bottom:0.5em; }

.chart-tooltip-fixed                      { color:#999999; margin: 0; padding: 10px; background-color: rgb(255, 255, 255); border: 1px solid rgb(204, 204, 204); white-space: nowrap; }
.chart-tooltip-fixed .title-label         { color:#999999; font-size: 18px; width:100%; font-weight:normal; text-align:left; padding:0 2em 0 0 !important; }
.chart-tooltip-fixed .title-value         { color:inherit; font-size: 18px; font-weight:normal; width:100%; text-align:left; padding:0 !important; }
.chart-tooltip-fixed .label               { color:inherit; font-size: 12px; font-weight:normal; width:100%; text-align:left; padding:0 2em 0 0 !important; }
.chart-tooltip-fixed .value               { color:inherit; font-size: 12px; font-weight:normal; width:100%; text-align:left; padding:0 !important; }


.clear                                    { clear:both; }
.clickable                                { cursor:pointer; }
.command-bar                              { margin:.25em 0; white-space:nowrap; position:absolute; right:1em; background-color:#ecf0f5; }
.command-bar a,
.command-bar button                       { margin-left: .75em; }
.command-bar-inline                       { margin: 0 1em 1em; }
.comand-bar-inline a,
.command-bar-inline button                { margin-left: .75em; }
.content                                  { position:relative; padding-bottom: 75px; }
.content .nav-container                   { position:fixed; width:calc(100% - 270px - 0em); z-index: 2; padding: 0; margin:0; padding-right: .5em;}
.content nav                              { top:50px; left:230px; padding: .25em .5em; margin: 0; background-color: rgba(236, 240, 245); color:#444; border:1px solid #DDD; border-radius:0; }
.content nav.fullscreen                   { top:0px; left:0px;}
.control-label                            { font-size:16px; color:#8aa4af; padding-left:0; padding-top:0; padding-bottom:0; margin:0;}

.content-wrapper > .content               { padding: 0 0.25rem 2.5em !important; top: 3em !important; }

@media only screen and (max-width : 767px) {
  .content nav                              { top:100px; left:0px; }
}

.delimited-row						      { border-top: 1px dashed #ddd !important;  }
.disabled                                 { background-color:#EEE; color:#999; }
.dropdown-menu>li>a                       { color: #b8c7ce; }
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:active                { color: #FFFFFF; }
.dropdown-menu>.active .autocomplete-content { color: #FFFFFF; }
.dropdown-menu>.active>a                  { background-color: #468AFD; }
.dropdown-menu .autocomplete-content      { color:#333333; }

.fg-aqua                                  { color:#00c0ef !important; }
.fg-green                                 { color:#00a65a !important; }
.fg-forest                                { color:#003947 !important; }
.fg-red                                   { color:#dd4b39 !important; }
.fg-yellow                                { color:#f39c12 !important; }
.fg-gray                                  { color:#d2d6de !important; }
.fg-purple                                { color:#663399 !important; }
.fixed .main-header                       { top: 0; right: 0; left: 0; }
.fixed .left-side, 
.fixed .main-header, 
.fixed .main-sidebar                      { position: fixed; }
.form-control                             { border-radius:.33em !important; }
.form-control-autocomplete,
.form-control-date,
.form-control-no-info                     { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; padding-right: 0 !important; }
.form-control-feedback                    { right: 15px; }
.fs-36                                    { font-size: 36px !important; }
.form-control.fs-36                       { height: 60px !important; width:auto; }
.form-group                               { margin:0; }
.form-group div                           { padding-left:0; }

.glass                                    { position:relative;	display:inline-block; }
.glass:after                              {	content: ''; position:absolute;	top:1px; left:1px; width:calc(100% - 2px); height:50%; background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.2)); }
.glyphicon                                { color: #222d32; }

.h1, .h2, .h3, h1, h2, h3                 { margin-top: 12px; margin-bottom: 10px; }
.h4                                       { font-size: 16px; color: #8aa4af; margin:0; line-height:1.42857143; }
.h5                                       { font-size: 14px; color: #8aa4af; margin:0; }
.has-error .dropdown-toggle               { border-color:#d73925; background-color:#f2dede; }
.has-error .form-control                  { border-color:#d73925; }
.has-error .form-control-feedback.glyphicon { right:18px; }
.has-error .input-group-addon .glyphicon  { color: transparent; }
.hidden                                   { display:none; }
.highlight                                { background-color:rgba(255, 255, 0, 1); transition-timing-function: ease-in; animation: highlight-fade 3000ms forwards; }
@keyframes highlight-fade                 { from {background-color:rgba(255, 255, 0, 1);}     to {background-color:rgba(255, 255, 0, 0);} }

.info                                     { font-size:11px; color:#999;}
.inline-image                             { position:relative; top:-2px; }
.input-group-addon                        { background-color:#efefef !important; border-top-right-radius: .33em !important; border-bottom-right-radius: .33em !important; height:34px !important; }
.invalid-tab *                            { color:#dd4b39 !important; }

.l0                                       { left:0; }
.l-0_5em                                  { left:-0.5em; }
.l-6px                                    { left:-6px; }
.legend                                   { background-color: #FFFFFF; border: 1px solid rgba(0,0,0,0.1); padding: .3em 1em; margin: 1em; }
.legend .contents>div                     { margin:.25em 0; }
.legend .color-block                      { float:left; margin: 0 3px; width:20px; }
.legend label                             { padding:0; font-size:10px; display:block;}
.loading                                  { display:block; position:absolute; left:0; right:0; top:0; bottom:0; background:rgba(255, 255, 255, 0.8); -webkit-transition:all .3s ease; transition:all .3s ease; z-index:99000; pointer-events:none; width:100%; height:100%;}
.loading > div                            { position:absolute; display:block; text-align:center; width:100%; top:30%; left:0; font-size:inherit; color:rgba(0, 0, 0, 0.6); -webkit-transform:translateY(-52%); transform:translateY(-52%); -webkit-transition:all .3s cubic-bezier(.25, .46, .45, .94); transition:all .3s cubic-bezier(.25, .46, .45, .94) }
.loading > div > img                      { position:relative; top: -1px; left:3px; }
.loading.active                           { opacity: 1; pointer-events: all; }
.loading.active > div                     { -webkit-transform: translateY(50%); transform: translateY(50%) }

@media only screen and (max-width : 767px) {
  .main-footer                              { padding:0.25em; min-height: 0px; }
}

.main-header                              { position: fixed !important; max-height: 100px; z-index: 400 !important; margin: 0 !important; border: none !important; height: 3em /*height: 46px*/; background-color: #d73925; }
.main-header .logo                        { transition: width .3s ease-in-out; display: block; float: left; font-size: 22px; text-align: center; width: 270px; font-family: Helvetica Neue,Helvetica,Arial,sans-serif; padding: 0 15px; font-weight: 300; overflow: hidden; background-color: #d73925; color: #fff; border-bottom: 0 solid transparent; }
.main-header .navbar                      { background-color: #d73925; }
.main-header .sidebar-toggle              { float: left; background-color: initial; height:30px; color: #FFF; }
.main-sidebar                             { background-color: #222d32; height: 100vh !important; position: fixed !important; min-height: unset; overflow-y: unset; top: 0; left: 0; padding-top: 50px; width: 270px !important; z-index: 10 !important; transition: transform .3s ease-in-out,width .3s ease-in-out; }

.m0                                       { margin:0 !important; }
.mb-0_5em                                 { margin-bottom: 0.5em !important; }
.mb-1em                                   { margin-bottom: 1em !important; }
.mb-2em                                   { margin-bottom: 2em !important; }
.mb-3em                                   { margin-bottom: 3em !important; }
.ml-0_5em                                 { margin-left: 0.5em !important; }
.ml-1em                                   { margin-left: 1em !important; }
.ml-2em                                   { margin-left: 2em !important; }
.ml-3em                                   { margin-left: 3em !important; }
.mr-0_5em                                 { margin-right: 0.5em !important; }
.mr-1em                                   { margin-right: 1em !important; }
.mr-2em                                   { margin-right: 2em !important; }
.mr-3em                                   { margin-right: 3em !important; }
.mr-50px                                  { margin-right: 50px !important; }
.mt0                                      { margin-top:0 !important; }
.mt-0_5em                                 { margin-top: 0.5em !important; }
.mt-1em                                   { margin-top: 1em !important; }
.mt-2em                                   { margin-top: 2em !important; }
.mt-3em                                   { margin-top: 3em !important; }
.mt-50px                                  { margin-top: 50px !important; }
.mini-stat-box                            { width: 75%; padding:.6em; font-size:32px; border-radius:6px; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); line-height:0; margin:.25em auto; position:relative; display:inline-block; text-align: center;}
.mini-stat-box h3                         { font-size: 24px; }
.mini-stat-box .text                      { font-size:45%; vertical-align:top; position:relative; top:.5em; white-space:nowrap; font-weight: bolder; }
/* .MuiAccordionSummary-root                 { background-color: rgb(244, 246, 249) !important; border: 1px solid #CCC !important; border-radius: 4px; }
.MuiAccordion-root                        { background-color: #FFFFFF !important; box-shadow: none !important; } */
.MuiAutocomplete-popper                   { background-color: #FFFFFF !important; border: 1px solid #CCC; border-radius: 0 0 4px 4px;}
/* .MuiCollapse-root                      { background-color: #FFFFFF !important; box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important; } */
.MuiInputBase-root                        { background-color: #FFFFFF !important; }
.MuiFormLabel-root                        { background-color: #FFFFFF !important; padding: 0 .25em !important; }
/* .MuiPaper-root                            { margin: .5em !important } */
.MuiPaper-root::before                    { border: none !important; background-color: transparent !important; }
/* .MuiPopper-root, .base-Popper-root        { z-index: 3 !important; } */
/* .MuiDialog-root                           { z-index: 3 !important; } */
.base-Popper-root.assessment-guidance     { z-index: 2 !important; }
.MuiPopper-root.assessment-guidance     { z-index: 2 !important; }
.help-dialog              { z-index: 400 !important; }
.MuiAccordion-root.Mui-expanded           { margin-top: 0 !important; margin-bottom: 0 !important; }                
.MuiTableCell-root                        { padding: 4px !important; font-size: .85rem !important; }
.MuiTableCell-root:first-child            { padding-left: 5px !important; }
.navbar-fixed-bottom                      { -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; transition: transform 0.3s ease-in-out, left 0.3s ease-in-out !important; bottom: 0 !important; position: fixed !important; left: 0 !important; right: 0 !important; z-index: 10;}
.navbar-fixed-top                         { height: 50px; -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out; -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out; -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out; transition: transform 0.3s ease-in-out, left 0.3s ease-in-out; }
.navbar-static-top                        { z-index: 10; border-width: 0 0 1px; }

.overflow-visible                         { overflow:visible !important; }

.p0                                       { padding:0; }
.page-content                             { padding-top:0em; height:600px;}
.page-content-adjusted                    { padding-top:3.2em; }
/* .page-content-adjusted .MuiGrid2-root     { padding: 0 1em !important; } */
.panel .panel-body                        { position:relative; }
.panel .panel-body .loading  > div        { top:40%; }
.panel-header                             { padding-left: 1em;}
.pb-1em                                   { padding-bottom: 1em; }
.pr-1em                                   { padding-right: 1em; }
.pt0                                      { padding-top:0 !important; }
.ptb-0_5em                                { padding-top: .5em; padding-bottom: .5em; }
.ptb-1em                                  { padding-top: 1em; padding-bottom: 1em; }

.react-datepicker                         { cursor:pointer; font-size:12px !important; }
.react-datepicker__close-icon             { display: none; }
.react-datepicker__input-container        { width:100%; }

.ReactTable.ie-table-short                { height:200px; }
.ReactTable.ie-table-tall                 { height:780px; }
.ReactTable,
.ReactTable .-pagination                  { background-color:#ffffff; }
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td              { overflow: visible;}
.ReactTable .rt-th .btn                   { font-size: 75%; }
.ReactTable .rt-th .dropdown-menu         { min-width:80px; background-color: #333; }
.ReactTable .rt-th .dropdown-menu>li>a:focus,
.ReactTable .rt-th .dropdown-menu>li>a:hover {background-color: #222d32;}
.reflection                               { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 50%); pointer-events: none; }
.relative                                 { position: relative; }
.report-summary-panel                     { border: 1px solid #CCCCCC; background-color: #FFFFFF; border-radius: 4px; padding:.5em; margin: 1em; height: 12em; overflow:hidden; }
.report-summary-panel div div             { margin:0; padding:0; }
.report-summary-panel .title              { font-size:14px; font-weight:400; }
.report-summary-panel .icon-open          { cursor:pointer; }
.report-summary-panel .subtitle           { font-weight: bold; }
.report-summary-panel .label,
.report-summary-panel .value              { color:#333333; font-size:12px; text-align:left; padding:0 1em; overflow:hidden; display:inline-block; width:100%; }
.roles-select                             { width:310px; }

form .row                                 { padding:0.33em 0; min-height:3.17em; margin:0; }
.row                                      { margin:0; }
.row.row-odd                              { background-color: transparent; }
.row.row-even                             { background-color: rgba(144, 144, 144, 0.1); }

.Select-menu-outer                        { z-index: 840; }
.Select-option                            { padding-left: .25em !important; padding-right: .25em !important; }
.session-expired-overlay                  { display:block; position:absolute; left:0; right:0; top:0; bottom:0; background:rgba(34, 45, 50, 0.9); z-index:99000; pointer-events:none; width:100%; height:100%; }
.shadow                                   { box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.sidebar-collapse .navbar-fixed-top       { left:0 !important; }
.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar::before { margin-left: -270px !important; }
.sidebar-collapse .content .nav-container { width:calc(100% - 0em); padding-right: 1em; overflow-y: auto; }
.sidebar                                  { padding: 0 !important; height: calc(100vh - 60px); overflow-y: auto;}
.sidebar-menu li                          { width: 270px; position: relative; }
.sidebar-menu li .submenu-icon            { position: absolute; right: 10px; }
.sidebar-menu li.divider                  { border-bottom: 1px dashed #8aa4af; margin: .3em 1em; }
.sidebar-menu li .treeview-menu           { margin: 0 1px; background: #2c3b41; padding-left: .6em; }
.sidebar-menu li .treeview-menu li        { margin-left:10px; }
.sidebar-menu li.whitespace-wrap          { white-space: wrap !important; }
.sidebar-menu li a                        { color: #8aa4af; padding: 5px 2px 5px .5em; display: block; white-space: nowrap; line-height: 1.42857143; }
.sidebar-menu li a:hover,
.sidebar-menu li a:active                 { color: #fff; }
.sidebar-menu li a .MuiSvgIcon-root       { font-size: 18px; margin: 0 .4em .25em .25em; }
.sidebar-menu .help-button                { color: #8aa4af; padding: 5px 2px 5px .5em; display: block; white-space: nowrap; line-height: 1.42857143; text-transform: capitalize; font-size: inherit; }
.sidebar-menu .help-button .MuiButton-startIcon { display: unset; margin: unset; }
.sidebar-menu .help-button:hover          { color: #fff; }
.sidebar-menu .help-button .MuiSvgIcon-root       { font-size: 18px; margin: 0 .25em .25em; }
.sidebar-menu .MuiButtonBase-root                   { font-size: inherit !important; }
.sidebar-menu .MuiButtonBase-root.MuiButton-root    { padding: 5px 2px 5px .5em !important; }


.snapshot-mode-label                      { color: #FFF; position:fixed; top:102px; right:0; z-index:300; background-color:#f39c12; padding: 0.4em 1em; }
.snapshot-mode-label .glyphicon           { color: #FFF; }
.stats                                    { font-size: 28px; }
.status-losing                            { background-color: #dd4b39 }
.status-tbd                               { background-color: #d2d6de; }
.status-winning                           { background-color: #00a65a; }
.status-is-winning                        { color:#FFFFFF; padding: 2px; width:100%; }

sup                                       { font-size:10px; top:-.75em; font-size:16px; }

.tab-content                              { background-color:#ffffff; padding: 1em; border: 1px solid #ddd; border-top:none; border-bottom-left-radius:4px; border-bottom-right-radius:4px; min-height:20em; }
.tis-commandbar button                    { margin: .2em .2em !important; padding: 4px 8px !important;}
.tis-breadcrumb-container                 { margin: 0 !important; }
.tis-breadcrumb                           { top: 0 !important; left: 0 !important; padding: .2em .2em !important; }
.tis-breadcrumb h1                        { font-size: 20px !important; margin: 0 !important; padding: 0 !important; vertical-align: bottom !important; }
.tis-breadcrumb .MuiSvgIcon-root          { font-size: 20px !important; margin: 0 !important; position: relative !important; top: -1px !important; }
.tis-breadcrumb .MuiButtonBase-root       { font-size: .85rem !important; }
.tis-form-button                          { margin: 1em 0em !important;  }
.tis-form-field                           { margin: .5em 0em !important; }
.tis-form-table                           { margin: .5em 0em !important; }
.tis-form-text                            { margin: .5em .25em 0em !important; font-size: 14px !important; }
.tis-form-title                           { font-weight: bold !important; }
.tis-mfi-guidance                         { position:relative; margin: 0 auto; min-width: 16em; max-width: 50%; background-color: #CCCCFF !important; z-index: auto; border: 1px solid #6666AA; border-radius: 1em !important; box-shadow: 0px 8px 4px -4px rgba(0,0,0,0.2), 0px 4px 4px 0px rgba(0,0,0,0.14), 0px 4px 12px 0px rgba(0,0,0,0.12) !important; }
.tis-mfi-guidance h2                      { padding: .25em 0 .25em .5em; font-size: 24px; }
.tis-help-icon                            { font-size: 2rem !important; color:#999999; border-radius: 40px; border:3px solid transparent; cursor: pointer; }
.tis-help-icon:hover                      { font-size: 2rem !important; color:#6666AA; border-radius: 40px; border:3px solid #6666AA !important; }
.tis-page                                 { width: 100%; }
.tis-quality-and-fs-category              { background-color: transparent !important; box-shadow: none !important; }
.tis-quality-and-fs-category > .MuiCardHeader-root { padding: 8px; background-color: #E6B8B7 !important; border-radius: 1em 0 0 1em; border: 1px solid #e79594 !important; cursor: pointer; margin-bottom: .25em !important; }
.tis-quality-and-fs-category .MuiCollapse-root { box-shadow: none !important; border: none !important; background-color: transparent !important; }
.tis-quality-and-fs-category .tis-collapse { width: 100%; text-align: center; cursor: pointer; padding: .9em 0 .25em 0; }
.tis-quality-and-fs-category .MuiCardContent-root { padding: 1em; }
.tis-quality-and-fs-section               { background-color: transparent !important; box-shadow: none !important; margin: 0 0 .25em 1em !important; }
.tis-quality-and-fs-section > .MuiCardHeader-root { background-color: #FCD5B4 !important; border-radius: 1em 0 0 1em; border: 1px solid #f9bc89 !important; cursor: pointer; }
.tis-quality-and-fs-section .MuiCardContent-root { width: calc(100% - 12px) !important; margin: 1px 0 1em 12px; padding: 0 2em 0 0; background-color: #FFF; border: 1px solid #ccc; }
/* box-shadow: 0px 8px 4px -4px rgba(0,0,0,0.2), 0px 4px 4px 0px rgba(0,0,0,0.14), 0px 4px 12px 0px rgba(0,0,0,0.12) !important; } */
.tis-quality-and-fs-section .MuiCollapse-root { box-shadow: none !important; border: none !important; }
.tis-quality-and-fs-questions div        { font-size: 16px !important; line-height: 1.25 !important; padding-right: 5px !important;}
.tis-quality-and-fs-questions *          { font-weight: 500 !important; }

.assessment-item-score-box               { padding: 2px 5px 4px 2px !important; }
.tis-quality-and-fs-item.MuiGrid2-container .MuiGrid2-root   { padding: 2px 10px 4px 2px !important }
.tis-quality-and-fs-item-id               { border-right: 2px dashed #CCC; border-bottom: 2px dashed #CCC; margin-bottom: 6px; margin-left: 0px; margin-right: -5px; margin-top: -5px; padding-top: 3px; padding-bottom: 2px; font-weight: bold; font-size: 20px; text-align: center;}
.tis-quality-and-fs-item.MuiGrid2-container { padding: 1em 0em 0em 3em !important; }
.tis-quality-and-fs-item .points-label    { text-align: center; font-size: 14px; margin: .5em 0 0;}
.tis-quality-and-fs-item .points          { width: 90%; max-width: 4em; min-height: 2.5em; background-color: rgb(0, 151, 196); border: 3px solid rgb(28, 82, 95); color: #FFF;  border-radius: 20px; padding: .1em 10%; margin:0 auto .5em auto; font-weight: bold; }

.tis-quality-and-fs-item .points .earned-points { font-size: 20px; border-bottom: 3px solid #FFF; text-align: center; }
.tis-quality-and-fs-item .points .max-points { font-size: 20px; text-align: center; }
.tis-quality-and-fs-item .title           { font-size: 18px; color: #333; font-weight: bold; margin: 0 0 .5em !important; }
.tis-quality-and-fs-item-not-included     { background-color: #EEE; color: #999 !important; margin: 0 !important; }
.tis-quality-and-fs-item-not-included .MuiInputBase-root, 
.tis-quality-and-fs-item-not-included .MuiFormLabel-root {background-color: #EEE !important;; color: #999 !important; }
.tis-quality-and-fs-item .separator { border-bottom: 2px dashed #CCC; display: block; width: 100% }
.tis-quality-and-fs-item .cap-entry-form { display: block; width: 100%; }

.tis-cap-items-header-bar       { width: 100%; padding-bottom: 8px; padding: 0 10px 3px 10px; margin: 0 0 0 0 !important;}
.tis-cap-items-header-bar .tis-cap-title-points     { text-align: left; }
.tis-cap-items-header-bar .tis-cap-assessment-rating     { text-align: left; }
.tis-cap-category              { background-color: transparent !important; box-shadow: none !important; width: 100%; }
.tis-cap-category > .MuiCardHeader-root { padding: 8px; background-color: #DDD !important; border: 1px solid #BBB !important; cursor: pointer; margin-bottom: 0 !important; }
.tis-cap-category .MuiCollapse-root { box-shadow: none !important; border: none !important; background-color: transparent !important; }
.tis-cap-category .tis-collapse { width: 100%; text-align: center; cursor: pointer; padding: 0; }
.tis-cap-category .MuiCardContent-root { padding: 0; }
.tis-cap-category .MuiCardContent-root:last-child { padding-bottom: 0; }
.tis-cap-section               { background-color: transparent !important; box-shadow: none !important; margin: 0 !important; width: 100%; }
.tis-cap-section > .MuiCardHeader-root { background-color: #EEE !important; border: 1px solid #CCC !important; cursor: pointer; margin-bottom: 0 !important; padding: 8px; padding-left: 16px}
/* .tis-cap-section .MuiCardContent-root { width: calc(100% - 12px) !important; margin: 1px 0 1em 12px; padding: 0 2em 0 0; background-color: #FFF; border: 1px solid #ccc; } */
.tis-cap-section .MuiCardContent-root { width: 100% !important; margin: 0; padding: 0; background-color: #FFF; border: 1px solid #ccc; }
.tis-cap-section .MuiCardContent-root:last-child  { padding-bottom: 0;}
.tis-cap-section .MuiCollapse-root { box-shadow: none !important; border: none !important; }
.tis-cap-questions *           { font-weight: bold !important; }
.tis-cap-question-panel           { background-color: #EEE; padding: 5px; font-weight: bold !important; }

.tis-cap-item-card               { background-color: transparent !important; box-shadow: none !important; margin: 0 !important; width: 100%; }
.tis-cap-item-card > .MuiCardHeader-root { background-color: #FFF !important; border: 1px solid #CCC !important; 
                                          /* cursor: pointer;  */
                                          margin-bottom: 0 !important; padding: 8px;}
.tis-cap-item-card .MuiCardContent-root { width: 100% !important; margin: 0; padding: 0; background-color: #FFF; border: 1px solid #ccc; }
.tis-cap-item-card .MuiCardContent-root:last-child  { padding-bottom: 0 !important; }
.tis-cap-item-card .MuiCollapse-root { box-shadow: none !important; border: none !important; }
.tis-cap-item-card .tis-cap-title-bar { align-content: center; align-self: center;}

.no-finding .tis-cap-assessment-rating { color: rgb(0, 196, 121); }
.improvement-opportunity .tis-cap-assessment-rating { color: rgb(0, 196, 121); }
.non-conformance .tis-cap-assessment-rating { color: rgb(233, 171, 12); }
.conditional-non-conformance .tis-cap-assessment-rating { color: rgb(195, 82, 82); }
.high-risk-non-conformance .tis-cap-assessment-rating { color: rgb(195, 82, 82); }

.tis-cap-title-bar .tis-cap-assessment-rating                { text-align: left; }
.tis-cap-title-bar .tis-cap-title-points { text-align: left;}
.tis-cap-title-bar .MuiStack-root { align-content: center; align-self: center; align-items: center;}
.tis-cap-title-bar .MuiGrid2-root { align-content: center; align-self: center; align-items: center;}
.tis-cap-item-card .MuiCardHeader-action { align-self: center;}

.tis-cap-status-globe           { margin: 0; margin-right: 10px; border: 2px solid; width: 2rem; height: 2rem; border-radius: 50%;}
.tis-cap-status-globe.cap-not-started { background-color: #808080; border-color: grey; }
.tis-cap-status-globe.cap-review { background-color: #ffff00; border-color: yellow; }
.tis-cap-status-globe.cap-approved { background-color: #ffa500; border-color: orange; }
.tis-cap-status-globe.cap-in-process { background-color: #7EA6E0; border-color: #7EA6E0; }
.tis-cap-status-globe.cap-in-process-late { background-color: #ff0000; border-color: red; }
.tis-cap-status-globe.cap-completed { background-color: navy; border-color: navy; }
.tis-cap-status-globe.cap-finalized { background-color: #008000; border-color: green; }

.tis-cap-item-id               { 
  /* border-right: 2px dashed #CCC; 
  border-bottom: 2px dashed #CCC; 
  margin-bottom: 1em; 
  margin-left: -12px; 
  margin-top: -25px;  */
  font-weight: bold; font-size: 18px; text-align: center; padding: 0 !important;}
.tis-cap-item.MuiGrid2-container .MuiGrid2-root   { padding: .5em 2em .5em 1em !important }
/* .tis-cap-item.MuiGrid2-container { padding: 1em 0em 0em 3em !important; } */
.tis-cap-item .points-label    { text-align: center; font-size: 14px; margin: 1em 0 0;}
.tis-cap-item .points          { max-width: 4em; min-height: 2.5em; background-color: rgb(0, 151, 196); border: 3px solid rgb(28, 82, 95); color: #FFF;  border-radius: 20px; padding: .03em; margin:0 auto .1em auto; font-weight: bold; }
.tis-cap-item .points .earned-points { font-size: 18px; border-bottom: 3px solid #FFF; text-align: center; }
.tis-cap-item .points .max-points { font-size: 18px; text-align: center; }
.tis-cap-item .title           { font-size: 18px; color: #333; font-weight: bold; margin: 0 0 .5em !important; }
.tis-cap-item .separator { border-bottom: 2px dashed #CCC; display: block; width: 100% }
/* .cap-entry-form { display: block !important; width: 100%; } */
.cap-entry-form.MuiGrid2-root            {  width: 100%; padding: 0 !important; }


.points.no-finding { background-color: rgb(0, 196, 121); border: 3px solid rgb(28, 95, 42); }
.points.improvement-opportunity { background-color: rgb(0, 196, 121); border: 3px solid rgb(28, 95, 42); }
.points.non-conformance { background-color: rgb(233, 171, 12); border: 3px solid rgb(114, 79, 22); }
.points.conditional-non-conformance { background-color: rgb(195, 82, 82); border: 3px solid rgb(104, 30, 30); }
.points.high-risk-non-conformance { background-color: rgb(195, 82, 82); border: 3px solid rgb(104, 30, 30); }

.treeview-menu                            { list-style-type: none; }

.unclickable                              { pointer-events:none; }

.visible                                  { display:block !important; }
.width-1                                  { width:8.33%; }
.width-2                                  { width:16.66%; }
.width-3                                  { width:25%; }
.width-4                                  { width:33.33%; }
.width-5                                  { width:41.66%; }
.width-6                                  { width:50%; }
.width-7                                  { width:58.33%; }
.width-8                                  { width:66.67%; }
.width-9                                  { width:75%; }
.width-10                                 { width:83.33%; }
.width-11                                 { width:91.66%; }
.width-12                                 { width:100%; }
.icon-bg                                  {color: #3c8dbc;}

div.im-log-form-page div.tis-inner-bar  { z-index: 1 !important; }

.authorization-preview-container {
  display: table;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 30px;
}

.authorization-preview-header             { display: flex; flex-direction: row; width: 100%; padding: 4px; }
.authorization-preview-logo               { width: 0%; }
.authorization-preview-logo > img         { width: 200px; }
.authorization-preview-title              { width: 100%; display: flex; justify-content: center; align-items: end;}
.authorization-preview-title > h1         { margin-bottom: 0px;}
.authorization-preview-row-buffer         { width: 100%; height: 20px; }
.authorization-preview-info-section       { display: flex; flex-direction: column; width: 100%; padding: 20px; }

.authorization-preview-container > table {
  border-collapse: collapse;
  width: 100%;
}

.align-center                             {  text-align: center;}
.authorization-preview-container > table > tbody > tr {
width: 100%;
}
.authorization-preview-container > table > tbody > tr > th {
  border: 1px solid #dddddd;
  padding: 8px;
  background-color: #DDE5F0;
}

.authorization-preview-container > table > tbody > tr > td {
  border: 1px solid #dddddd;
  padding: 8px;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .authorization-preview-header {
    position: fixed;
    top: 50px;
  }
  .authorization-preview-footer {
    position: fixed;
    bottom: 0;
  }
}
.MuiInputBase-root                        { font-size: 0.9rem !important; }
.MuiTypography-body1                      { font-family: unset !important; font-size: 0.9rem !important; }
.MuiTypography-body2                      { font-family: unset !important; font-size: 0.8rem !important; }
.MuiInputBase-root.MuiOutlinedInput-root { padding-left: 8px !important; padding-bottom: 4px !important; padding-top: 4px !important; padding-right: 8px !important; }
.MuiInputBase-input.MuiOutlinedInput-input { padding-left: 3px !important; padding-bottom: 4px !important; padding-top: 4px !important; }
.MuiSelect-select                     { padding-left: 4px !important; padding-top: 4px !important; padding-bottom: 4px !important; padding-right: 32px !important; }
.MuiButtonBase-root                   { font-size: 0.8rem !important; }
.MuiButtonBase-root.MuiButton-root    { padding: 4px 8px !important; }
.MuiButtonBase-root.MuiTab-root      { padding: 8px !important; }
.MuiButtonBase-root.MuiSvgIcon-root   { top: -1px !important; }
.MuiAutocomplete-root                     { font-size: 0.9rem !important;  }
.MuiAutocomplete-input                     { padding-left: 2px !important; padding-top: 2px !important; padding-bottom: 2px !important;  }
.MuiInputLabel-root                     { font-size: 0.9rem !important; }
.MuiMenuItem-root                     { font-size: 0.9rem !important; }
.MuiInputBase-input::placeholder        { font-size: 0.9rem !important; }
.MuiSelect-select::placeholder        { font-size: 0.9rem !important; }
.MuiOutlinedInput-root.MuiInputBase-sizeSmall {  padding-left: 4px !important; padding-top: 4px !important; padding-bottom: 4px !important; font-size: 0.9rem !important; }

.authorization-approval-text-input .MuiInputBase-input.MuiOutlinedInput-input { font-family: unset !important; padding-left: 2px !important; padding-bottom: 0px !important; padding-top: 1px !important; }

.MuiSkeleton-root {
  -webkit-transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -ms-transform: scale(1, 1) !important;
  transform: scale(1, 1) !important;
}

.authorization-change-toggle.MuiFormGroup-root {
  float: left;
  margin-right: 4px;
  margin-top: -1px;
}

.authorization-change-toggle.MuiFormControlLabel-root {
  margin: 0;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 4px;
  border-radius: 4px;
  text-align: left;
}

.authorization-change-highlighted {
  background-color: #ffff79 !important;
}

.help-section { font-size: 0.85rem !important;}
.help-guidance                         { position:relative; background-color: #f7f7b9 !important; border: 1px solid #cacaca; border-radius: 1em !important; box-shadow: 0px 4px 4px -4px rgba(0,0,0,0.2), 0px 4px 4px 0px rgba(0,0,0,0.14), 0px 4px 4px 0px rgba(0,0,0,0.12) !important; }
.help-guidance .MuiCardHeader-root     { padding: 8px 14px !important; }
.help-guidance .MuiCardHeader-title    { font-size: 0.90rem !important; }
.help-guidance .MuiCardContent-root    { font-size: 0.85rem !important; padding: 8px 14px !important; }

.letter-edit-highlighted {
  border: 2px solid red;
  padding: 2px;
}

/* .menu-selected {background-color: #596577;} */
li.menu-selected > a { font-weight: 500; color: rgb(226, 224, 234) !important; }

.columnar-layout {
  display: flex;
  align-items: center;
}

.flex-rows {
  display: flex;
  flex-direction: column;
}

.contents-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.contents-center {
  display: flex;
  justify-content: center;
}

.contents-left {
  display: flex;
  justify-content: flex-start;
}

.contents-middle {
  display: flex;
  align-items: center;
}

.flex-gap-sm {
  gap: 20px;
}
.flex-gap-xs {
  gap: 10px;
}

.body-color {
  color: rgba(0, 0, 0, 0.6)
}

.pill {
  display: inline-block;
  padding: 8px 8px;
  border-radius: 999px;
  background-color: #d3d3d3; /* Light gray */
  color: #333; /* Darker text for contrast */
  /* font-size: 14px;
  font-weight: 500; */
  text-align: center;
  white-space: nowrap;
}