.featured-article {
  position: relative;
  width: 100%;
  /* max-width: 800px; */
  height: 100%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

/* Gradient Overlay */
.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: linear-gradient(to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
}

.featured-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-controls {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  /* background-color: rgba(255, 255, 255, 0.8); */
  border-radius: 8px;
  padding: 6px 12px;
}

.icon-button {
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1) !important;
  transform: scale(1);
  transition: transform .2s ease;
}

.icon-button:hover {
  transition: transform .2s ease;
  transform: scale(1.2);
}

.draft-label {
  background-color: yellow;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  width: 100px;
}

.edit-delete-icons {
  display: flex;
  gap: 8px;
}

.article-info {
  position: absolute;
  bottom: 12px;
  left: 12px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: white;
  padding: 12px;
  border-radius: 8px;
}

.nav-buttons {
  z-index: 100000;
  position: absolute;
  bottom: 12px;
  right: 12px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: white;
  padding: 12px;
  border-radius: 8px;
}

.article-click-container {
  width: 100%;
  height: 100%;
  display: flex;
  /* z-index: 99999; */
}

.control-button {
  border-color: #ccc;
  border-width: 1px;
}

.nav-button {
  /* top: 50%; */
  /* transform: translateY(-50%); */
  color: white;
  background-color: white;
  transform: scale(1);
  transition: transform 0.2s;
}

.nav-button:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}



.nav-button.left {
  left: 8px;
}

.nav-button.right {
  right: 8px;
}


.secondary-articles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.tertiary-article-card {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.secondary-article-card {
  flex-grow: 1; /* Distributes available space evenly */
  min-height: 100px; /* Prevents cards from becoming too small */
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}


/* .secondary-article-card:hover {
  transform: scale(1.03);
} */

/* Semi-transparent overlay to enhance text readability */
.image-overlay {
  position: absolute;
  /* inset: 0; */
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .6), rgba(0, 0, 0, 0));
}

.secondary-article-content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: white;
  padding: 6px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}
.secondary-article-title {
  font-size: 1rem;
  /* background-color: rgba(0, 0, 0, 0.6); */
  color: white;
  text-align: left;
}

.tertiary-articles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.tertiary-article-card {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.tertiary-article-image {
  width: 150px;
  height: 100px;
  background-size: cover;
  background-position: center;
}

.tertiary-article-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  position: relative;
}

.tertiary-article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tertiary-article-title {
  font-size: 1rem;
  font-weight: bold;
}

.draft-label {
  background-color: yellow;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  height: 34px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.tertiary-article-description {
  margin-top: 4px;
  font-size: 0.9rem;
  color: #555;
}

.tertiary-article-meta {
  font-size: 0.8rem;
  color: #777;
  margin-top: 4px;
}

.tertiary-article-actions {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 6px;
}

.article-section:hover {
  border: 2px dashed #ccc;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 6px;
  /* background-color: rgb(220, 221, 229); */
}

.article-section:hover .add-icon {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 9999999;
}

.article-section .add-icon {
  display: none;
}
.article-controls-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 4px 20px 4px 20px;
  display: flex;
  gap: 8px;
}

.fit-height {
  height: 100% !important;
}
