.highlightColorYellow {
  background-color: rgb(255, 255, 0);
}

.noSpacingAbove {
  margin-top: 0;
  padding-top: 0;
}

.noSpacingBelow {
  margin-bottom: 0;
  padding-bottom: 0;
}

.spacingBelow {
  margin-bottom: .6em;
}

ul.customListStyle {
  padding-left: 48px;
}

.article-body ul {
  margin-left: 8px !important;
}

.article-body blockquote {
  border-left: none !important;
  border-right: none!important;
  clear: both;
  font-family: Segoe UI Regular WestEuropean, Segoe UI, Tahoma, Arial, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 32px;

  color: rgb(96, 94, 92);
  border-bottom: 1px solid rgb(237, 235, 233);
  border-top: 1px solid rgb(237, 235, 233);
}

.article-body blockquote p {
  font-size: 20px;
  line-height: 1.3;
  margin: 0;
}

.article-body blockquote:not(.semanticQuote) p {
  font-weight: 600;
  font-style: italic;
  text-align: center;
}

.article-body h2.headingSpacingBelow, h3.headingSpacingBelow, h4.headingSpacingBelow {
  margin-bottom: 0;
  padding-bottom: .6em;
}

.horizontalSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.horizontalSection .fullWidth, .oneColumn {
  width: '100%'
}

.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.imagePlugin {
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 4px;
}

.floatLeft {
  float: left;
}

.imagePlugin.floatLeft {
  margin-right: 16px;
}

.imagePlugin.floatRight {
  margin-left: 16px;
}

.webpart {
  margin-bottom: 30px;
}

figure figcaption {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6em;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}